import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Form from "../components/Form";
import BackgroundImage from "gatsby-background-image";
import IconForm from "../images/icon-form.svg";
import IconOffers from "../images/icon-offers.svg";
import IconRocket from "../images/icon-rocket.svg";
import { globalMobileWidth } from "../utils/formUtils";

const TravelForm = styled.section`
  background: #fdfdfd;
  display: flex;
  .col-right {
    flex-basis: 40%;
    background: #fdfdfd;
    form {
      margin: 0 auto;
      background: #fdfdfd;
    }
    @media all and (max-width: ${globalMobileWidth}px) {
      flex-basis: 100%;
      padding: 0;
    }
  }
  @media all and (max-width: ${globalMobileWidth}px) {
    flex-wrap: wrap;
  }
  .info-section {
    text-align: center;
    padding: 30px 0 20px 0;
    background-color: #f4f5f7;

    @media all and (max-width: ${globalMobileWidth}px) {
      max-width: 100%;
      padding: 10px 0;
    }
  }
  .info-heading {
    margin-bottom: 0px;

    @media all and (max-width: ${globalMobileWidth}px) {
      font-size: 20px;
      margin-bottom: 0px;
      padding-top: 0px;
    }
  }
  .icons-wrapper {
    color: #585858;
    max-width: 310px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: "Open Sans", arial, sans-serif;
    padding-top: 20px;
    img {
      height: 24px;
      margin-right: 20px;
    }
    @media all and (max-width: ${globalMobileWidth}px) {
      min-height: 136px;
      max-width: 293px;
    }
  }
  .text {
    font-size: 14px;
    text-align: center;
    margin: auto 0;
  }
  .icon {
    display: flex;
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  background-position: center;
  background-size: 1550px;
  flex-basis: 60%;
  position: relative;
  &:before {
    background-size: cover !important;
  }
  @media all and (max-width: ${globalMobileWidth}px) {
    flex-basis: 100%;
    &:before {
      background-image: none !important;
      background-color: #081a31;
    }
  }
  @media all and (min-width: 2400px) {
    &:before {
      background-size: cover !important; // Required to override gatsby
    }
  }
  .heading-blurb {
    max-width: 627px;
    background: rgba(0, 0, 0, 0.8);
    font-family: "Montserrat", "Open Sans", arial, sans-serif;
    padding: 30px;
    color: #fff;
    position: absolute;
    right: 0;
    top: 460px;
    @media all and (max-width: 1050px) {
      top: 400px;
      max-width: 100%;
    }
    @media all and (max-width: ${globalMobileWidth}px) {
      background: #fff;
      display: block;
      margin-top: 55px;
      position: static;
      padding: 10px 20px;
    }
  }
  h3 {
    color: #fff;
    font-size: 2rem;
    margin: 0 0 0.5rem;
    padding: 0;
    @media all and (max-width: 1050px) {
      font-size: 36px;
    }
    @media all and (max-width: 830px) {
      font-size: 30px;
    }
    @media all and (max-width: ${globalMobileWidth}px) {
      font-size: 16px;
      color: #081a31;
      margin: 0;
      text-align: center;
    }
    span {
      color: #3ac0a4;
    }
  }
  p {
    margin: 0;
    font-size: 20px;
    @media all and (max-width: ${globalMobileWidth}px) {
      display: none;
    }
  }
`;

const FormLander = () => {
  const { formImage } = useStaticQuery(graphql`
    query NorgFormLanderQuery {
      formImage: file(relativePath: { eq: "norg-landing-page-image.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const trackingId =
    (typeof window !== "undefined" &&
      window.location.pathname
        .replace(/^[/]/, "")
        .replace(/\/$/, "")
        .replaceAll(/[/]/g, "-")
        .concat("-fg")) ||
    "norg-form-lander-fg";
  return (
    <TravelForm>
      <StyledBackgroundImage fluid={formImage.childImageSharp.fluid}>
        <div className="heading-blurb">
          <h3>Welcome to TravelNursing.org, Nurse.org’s trusted partner.</h3>
        </div>
      </StyledBackgroundImage>

      <div className="col-right">
        <div className="info-section">
          <h3 className="info-heading">Here's how this works:</h3>
          <div className="icons-wrapper">
            <div className="icon">
              <img src={IconForm} alt="Computer screen icon" />
              <div className="text">Sign up using the form below</div>
            </div>
            <div className="icon">
              <img src={IconOffers} alt="People with chat boxes icon" />
              <div className="text">Receive calls from up to 4 agencies</div>
            </div>
            <div className="icon">
              <img src={IconRocket} alt="Rocket icon" />
              <div className="text">Choose your best offer!</div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Form
            id="fg-norg-form-lander"
            formId={"66b24e00117f45d688ce659e"}
            trackingId={trackingId}
            v3={true}
          />
        </div>
      </div>
    </TravelForm>
  );
};

export default FormLander;
